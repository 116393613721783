.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
  }

  .banner{
    position: absolute;
    width: 100%;
    height:auto;
    left: 0;
    object-fit:cover;
    z-index: 1;
    top:-5%;
  }

  .bg{
    position: absolute;
    width: 100vw;
    height:100%;
    top: 0;
    left: 0;
    object-fit:cover;
  }

  .boxWrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
  }

  .box{
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../images/Section2/box.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .boxForeground{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    /* background-color: #53A646; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* border: 3px solid black; */
  }

  .boxBackground{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: #3F8236; */
  }

  .ca{
    margin: 0;
    font-family: var(--font-bahiana);
    color: white;
    background-color:transparent;
    border:none;
    overflow-wrap: anywhere;
    text-align: start;
    box-sizing: border-box;
    padding: 10px;
  }

  .caTitle{
    margin: 0;
    font-family:var(--font-barriecito);
    color: white;
    text-align: start;
    z-index: 1;
    position: absolute;
    transform: translateY(-150%);
  }

  .copyWrapper{
    margin: 0;
    background-color: transparent;
    border: none;
  }

  .bg1,.bg2{
    height: auto;
    object-fit: contain;
    position: absolute;
    bottom: 0;
  }

  .bg1{
    left: 0;
  }

  .bg2{
    right: 0;
  }

  .emailWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(-50%,0%);
    z-index: 1;
    background-color: #50BC42;
    border: 2px solid black;
    padding: 15px;
    box-sizing: border-box;
    color: white;
  }

  .emailTitle{
    margin: 0;
    font-family: var(--font-barriecito);
  }

  .email{
    margin: 0;
    font-family: var(--font-bahiana);
    color: white;
  }

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height: 300px;
        min-height: 250px;
        min-width: 320px;
    }

    .subwrapper{
        flex-direction: column;
        gap: 30px;
    }

    .boxWrapper{
        width: 300px;
        height: 64px;
        gap: 100px;
        flex-direction: column;
    }

    .boxBackground{
        left: 10px;
        top: 10px;
        transform: rotate(0.5deg);
    }

    .ca{
        font-size: 16px;
    }
    .caTitle{
        font-size: 26px;
    }

    .copyIcon{
        width: 20px;
        height: auto;
    }

    .bg1,.bg2{
        width: 90px;
        content: url("../images/Section3/bg_mobile.png");
    }

    .emailWrapper{
        bottom:-100%;
        left: 50%;
        padding: 10px;
    }

    .email,.emailTitle{
        font-size: 12px;
    }
    
  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 450px;
        min-height: 400px;
    }

    .subwrapper{
        flex-direction: column;
        gap: 30px;
    }

    .boxWrapper{
        width: 400px;
        height: 100px;
        gap: 100px;
        flex-direction: column;
    }

    .boxBackground{
        left: 15px;
        top: 15px;
        transform: rotate(0.5deg);
    }

    .ca{
        font-size: 18px;
    }
    .caTitle{
        font-size: 30px;
    }

    .copyIcon{
        width: 30px;
        height: auto;
    }

    .bg1,.bg2{
        width: 200px;
        content: url("../images/Section3/bg.png");
    }

    .emailWrapper{
        bottom:-50%;
        left: 50%;
        padding: 10px;
    }

    .email,.emailTitle{
        font-size: 14px;
    }
    
  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 550px;
        min-height: 500px;
    }

    .subwrapper{
        /* max-width: 1440px; */
        padding: 50px;
        flex-direction: row;
    }

    .boxWrapper{
        width: 800px;
        height: 150px;
        gap: 100px;
    }

    .boxBackground{
        left: 25px;
        top: 25px;
        transform: rotate(0.5deg);
    }

    .ca{
        font-size: 24px;
    }
    .caTitle{
        font-size: 34px;
    }

    .copyIcon{
        width: 40px;
        height: auto;
    }

    .bg1,.bg2{
        width: 300px;
        content: url("../images/Section3/bg.png");
    }

    .emailWrapper{
        bottom:-40%;
        left: 50%;
    }

    .email,.emailTitle{
        font-size: 20px;
    }
  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 750px;
        min-height: 700px;
    }

    .subwrapper{
        max-width: 2200px;
        padding: 100px;
        flex-direction: row;
    }

    .boxWrapper{
        width: 1800px;
        height: 175px;
        gap: 200px;
    }

    .boxBackground{
        left: 35px;
        top: 35px;
        transform: rotate(1deg);
    }

    .ca{
        font-size: 32px;
    }
    .caTitle{
        font-size: 40px;
        margin: 10px;
    }

    .copyIcon{
        width: 50px;
        height: auto;
    }

    .bg1,.bg2{
        width: 400px;
        content: url("../images/Section3/bg.png");
    }

    .emailWrapper{
        bottom:-60%;
        left: 50%;
        padding: 30px;
    }

    .email,.emailTitle{
        font-size: 26px;
    }

  }

